import React from 'react'

import Benefits from '../benefits'
import ShopPhone from '../shop-phone'
import Testimonials from '../testimonials'
import UseInsurance from '../use-insurance'

import planTypes from '../../../images/plan-types.png'
import planPopularity from '../../../images/plan-popularity.png'

const HomeContentAlt = ({ hideCta = false, phone = '' }) => {
  return (
    <>
      <ShopPhone phone={phone} />
      <Benefits hideCta={hideCta} />
      <div className="base-section bg-dark tight">
        <div className="container flex mt3">
          <div className="w-100 w-50-l t-white t-center">
            <h3 className="h3 t-white pb2 t-bold">Medicare Coverage Types</h3>
            <img src={planTypes} alt="Medicare Coverage Types" />
            <p className="t-white t-small">
              *Plan N has up-to a $20 office visit co-pay, and up-to a $50 ER co-pay.
            </p>
          </div>
          <div className="w-100 w-50-l t-white t-center pt3 pt0-l">
            <h3 className="h3 t-white pb2 t-bold">Medicare Coverage Popularity</h3>
            <img src={planPopularity} alt="Medicare Coverage Popularity" />
            <p className="t-white t-small">*Source: Kaiser Family Foundation analysis.</p>
          </div>
        </div>
      </div>
      <UseInsurance />
      <Testimonials />
      <div className="bg-dark base-section tight t-white">
        <div className="container container-md-w-pad flex-mid t-center-s ph0">
          <div className="w-100 w-auto-l">
            <h3 className="t-bold">Still have questions about Medicare?</h3>
            <p className="mb0">
              Give us a call to speak with a licensed insurance agent now.
            </p>
          </div>
          <div className="w-100 w-auto-l pt3-m">
            <a href={`tel:${phone}`} className="btn with-icon btn-large">
              <span className="icon-phone" /> <span className="text">{phone}</span>
            </a>
            <div style={{ textAlign: 'center' }}>9:00am - 7:00pm ET</div>
          </div>
        </div>
      </div>
      <div className="base-section faq-section alt">
        <div className="container container-md">
          <div className="section-heading text-center">
            <h2>Frequently Asked Questions</h2>
          </div>
          <ul className="faq-list">
            <li>
              <a className="opener">Why should I use MedicareCompanion.org?</a>
              <div className="slide">
                <p>
                  MedicareCompanion.org helps you learn about your Medicare Plan options
                  and find a plan, all at once. Our licensed insurance agents can answer
                  your questions and help you find the right additional Medicare coverage
                  to fit your needs and budget.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">Do I have to pay for your help?</a>
              <div className="slide">
                <p>
                  No, we provide our services at no charge to you. We are happy to answer
                  your Medicare-related questions, help you compare your plan options, and
                  decide if additional Medicare coverage is right for you.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">What if I don't know what plan I need?</a>
              <div className="slide">
                <p>
                  Our US-based agents can help you assess what's important to you and find
                  an affordable plan that gives you peace of mind knowing you are covered.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">What if I already have a Medicare coverage?</a>
              <div className="slide">
                <p>
                  If you already have Medicare coverage, but want to change plans or lower
                  your premiums, you are in the right place. Simply call us at{' '}
                  <a href={`tel:${phone}`}>1-{phone}</a> or fill out the form at the top
                  or bottom of this page.
                </p>
              </div>
            </li>
            <li>
              <a className="opener">How do I get started?</a>
              <div className="slide">
                <p>
                  You can get started by filling out the form on the top or bottom of this
                  page or by calling us directly at <a href={`tel:${phone}`}>1-{phone}</a>
                  . We can help you through the entire process, from helping find a plan
                  that works for you to submitting your application.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default HomeContentAlt
