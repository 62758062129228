import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro/alt'
import HomeContentAlt from '../components/sections/home-content/alt'
import Partners from '../components/sections/partners'

import Surfers from '../images/surfers.png'

const phone = '866-545-3239'

const HomeCOne = ({ location }) => (
  <Layout
    phone={phone}
    location={location}
    footerAlt
    includeCitySearch
    hideOnline
    showCountDown
  >
    <SEO
      title="Medicare Supplement Plans - Medicare Companion"
      keywords={[`medicare`, `insurance`, `plans`, `affordable`, `supplement`]}
    />
    <Header
      phone={phone}
      className="new gray-style"
      grayLogo
      ctaText="<strong>Need Help?</strong> Speak with a <br/> Licensed Insurance Agent."
    />
    <Intro
      showTitle
      classNames="reverse"
      title="Get Additional Medicare Coverage"
      subTitle="Get a <i>FREE</i> quote in less than 2 minutes for better coverage."
      bgImage={Surfers}
      showImgContent
    >
      <div className="form-box">
        <div className="contacts-form alt text-center">
          <h4 className="title">
            Have questions about a plan? <br />
            Give us a call!
          </h4>
          <a href={`tel:${phone}`} className="btn with-icon btn-large">
            <span className="icon-phone" /> <span className="text">{phone}</span>
          </a>
        </div>
      </div>
    </Intro>
    <main id="main">
      <Partners showTitle={false} bg="bg-dark" />
      <HomeContentAlt phone={phone} hideCta />
    </main>
  </Layout>
)

export default HomeCOne
